<template>
	<div class="animated fadeIn">
		<div class="row">
			<div class="col">
				<b-button
					:variant="!showFilters ? 'primary' : 'secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</b-button>
				<b-button
					:disabled="loading"
					class="float-right"
					variant="success"
					@click="downloadReport">
					<i :class="!loading ? 'fas fa-file-excel' : 'fas fa-spinner fa-spin'" /> {{ translate('export') }}
				</b-button>
			</div>
		</div>
		<hr>
		<data-filter
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearFilters">
			<template slot="form">
				<div class="row">
					<div class="col-12 col-md-4">
						<search
							v-model="filters.id"
							:label-text="translate('autocomplete_label')"
							:endpoint="endpoint"
							:empty-result-message="translate('data_not_found')"
							:placeholder="'autocomplete_placeholder'"
							:default-params="{role: distributor}"
							class="mb-3"
							q="label"
							return-key="id" />
					</div>
					<div class="col-12 col-md-4">
						<div class="form-group">
							<label
								for="status"
								class="label">{{ translate('status') }}</label>
							<select
								id="status"
								v-model="filters.status"
								name="status"
								class="form-control">
								<option :value="undefined">
									{{ translate('all') }}
								</option>
								<option
									v-for="status in statuses"
									:key="status"
									:value="status">
									{{ translate(status) }}
								</option>
							</select>
						</div>
					</div>
					<div class="col-12 col-md-4">
						<div class="form-group">
							<label
								for="rank"
								class="label">{{ translate('rank') }}</label>
							<select
								id="rank"
								v-model="filters.rank"
								name="rank"
								class="form-control">
								<option :value="undefined">
									{{ translate('all') }}
								</option>
								<option
									v-for="item in ranksData"
									:key="item.id"
									:value="item.attributes.code_name">
									{{ translate(item.attributes.code_name) }}
								</option>
							</select>
						</div>
					</div>
					<div class="col-12 col-md-4">
						<div class="form-group">
							<label
								for="elite_level"
								class="label">{{ translate('elite_level') }}</label>
							<select
								id="elite_level"
								v-model="filters.elite_level"
								name="elite_level"
								class="form-control">
								<option :value="undefined">
									{{ translate('all') }}
								</option>
								<option
									v-for="item in eliteLevelsData"
									:key="item.id"
									:value="item.attributes.code_name">
									{{ translate(item.attributes.code_name) }}
								</option>
							</select>
						</div>
					</div>
					<div class="col-12 col-md-4">
						<div class="form-group">
							<label
								for="week"
								class="label">
								{{ translate('week') }}
							</label>
							<select
								v-model="filters.week_number"
								name="week"
								class="form-control text-capitalize"
								value="">
								<option value="all">
									{{ translate('all') }}
								</option>
								<option
									v-for="week in weeks"
									:key="week.attributes.number"
									:value="week.attributes.number">
									{{ translate('week_number', { number: week.attributes.number }) }}
								</option>
							</select>
						</div>
					</div>
				</div>
			</template>
		</data-filter>
		<div class="row">
			<div class="col">
				<div
					v-if="!loading"
					class="table-responsive">
					<table class="table table-hover table-striped text-nowrap">
						<thead>
							<tr>
								<th class="border-top-0">
									#
								</th>
								<th
									class="border-top-0 pointer"
									@click="sortByField('user_id')">
									{{ translate('user_id') }}
									<sort field="user_id" />
								</th>
								<th
									class="border-top-0 pointer"
									@click="sortByField('username')">
									{{ translate('username') }}
									<sort field="username" />
								</th>
								<th
									class="border-top-0 pointer"
									@click="sortByField('full_name')">
									{{ translate('full_name') }}
									<sort field="full_name" />
								</th>
								<th
									class="border-top-0 pointer"
									@click="sortByField('email')">
									{{ translate('email') }}
									<sort field="email" />
								</th>
								<th
									class="border-top-0 pointer"
									@click="sortByField('status')">
									{{ translate('status') }}
									<sort field="status" />
								</th>
								<th
									class="border-top-0 pointer"
									@click="sortByField('rank')">
									{{ translate('rank') }}
									<sort field="rank" />
								</th>
								<th
									class="border-top-0 pointer"
									@click="sortByField('elite_level')">
									{{ translate('elite_level') }}
									<sort field="elite_level" />
								</th>
								<th
									class="border-top-0 pointer"
									@click="sortByField('week_number')">
									{{ translate('week') }}
									<sort field="week_number" />
								</th>
							</tr>
						</thead>
						<tbody v-if="hasData">
							<tr
								v-for="(item, index) in data"
								:key="index">
								<td class="align-middle">
									{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
								</td>
								<td
									class="align-middle">
									<span
										class="badge badge-primary pointer"
										@click="toDashboard({ distributorId: item.attributes.user_id.toString() })">
										{{ item.attributes.user_id }}
									</span>
								</td>
								<td class="align-middle">
									{{ item.attributes.username }}
								</td>
								<td class="align-middle">
									{{ item.attributes.name }}
								</td>
								<td class="align-middle">
									{{ item.attributes.email }}
								</td>
								<td class="align-middle">
									{{ translate(item.attributes.status) }}
								</td>
								<td class="align-middle">
									{{ translate(item.attributes.rank) }}
								</td>
								<td class="align-middle">
									{{ translate(item.attributes.elite_level) }}
								</td>
								<td class="align-middle">
									{{ item.attributes.week_number }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>

<script>
import DataFilter from '@/components/DataFilter';
import { SEARCH_USERS } from '@/config/endpoint';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import FiltersParams from '@/mixins/FiltersParams';
import { YMD_FORMAT } from '@/settings/Dates';
import { PAGINATION } from '@/settings/RequestReply';
import { distributor } from '@/settings/Roles';
import { STATUSES } from '@/settings/Statuses';
import { Grids, Ranks } from '@/translations';
import Reports from '@/util/Report';
import Weeks from '@/util/Weeks';
import GENERAL_INFO from '@/util/GeneralInformation';

export default {
	name: 'RankHistories',
	messages: [Grids, Ranks],
	components: { DataFilter },
	mixins: [FiltersParams, DashboardRedirect],
	data() {
		return {
			alert: new this.$Alert(),
			reports: new Reports(),
			downloadRankHistories: new Reports(),
			weeksInfo: new Weeks(),
			endpoint: SEARCH_USERS.endpoint,
			statuses: STATUSES,
			distributor,
			ranks: new GENERAL_INFO(),
			eliteLevels: new GENERAL_INFO(),
		};
	},
	computed: {
		loading() {
			try {
				return this.reports.data.loading;
			} catch (error) {
				return false;
			}
		},
		pagination() {
			return this.reports.data.pagination;
		},
		data() {
			try {
				return this.reports.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		weeks() {
			try {
				return this.weeksInfo.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			try {
				return !!this.data.length;
			} catch (error) {
				return false;
			}
		},
		ranksData() {
			try {
				return this.ranks.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		eliteLevelsData() {
			try {
				return this.eliteLevels.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		loadedWeek() {
			try {
				return Number(this.reports.data.response.data.meta.week_number);
			} catch (error) {
				return 0;
			}
		},
	},
	watch: {
		loadedWeek(newVal) {
			if (typeof this.filters.week_number === 'undefined') {
				this.filters.week_number = newVal;
			}
		},
	},
	mounted() {
		this.ranks.getRanks();
		this.eliteLevels.getEliteLevels();
		this.weeksInfo.getWeeks();
		this.getRankHistories();
	},
	methods: {
		getRankHistories(params) {
			this.reports.getRankHistories(params);
		},
		getDataFiltered(page) {
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (this.filters.week_number === this.loadedWeek) {
				options.week_number = null;
			}
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}
			Object.keys(options).forEach((item) => {
				if (!options[item]) {
					delete options[item];
				}
			});
			this.$router.push({ name: this.$route.name, query: options }).catch(() => {});
			this.getRankHistories(options);
		},
		downloadReport() {
			this.alert.loading(this.translate('loading'), this.translate('loading_text'), { allowOutsideClick: false, allowEscapeKey: false });

			this.downloadRankHistories.downloadRankHistories().then(() => {
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(this.downloadRankHistories.data.response.data);
				link.setAttribute('download', `${this.translate('rank_histories')} - ${this.$moment().format(YMD_FORMAT)}.xlsx`);
				this.$el.appendChild(link);
				link.click();
			}).catch(() => {
			}).finally(() => {
				this.alert.close();
			});
		},
	},
};
</script>
